import { Drag } from 'components/Drag/Drag';
import { CarouselCard } from 'components/carouselCard/CarouselCard';
import {
  HeroVideoDesktopType,
  HeroVideoMobileType,
  HomepageHero,
} from 'components/homepageHero/HomepageHero';
import { PageIntroTextType } from 'components/pageIntroText/PageIntroText';
import { ImageType, ProjectType } from './project/[id]';
import { HomepageFooter } from 'components/homepageFooter/HomepageFooter';
import { ParallaxList, ParallaxListItem } from 'components/parallaxList/ParallaxList';
import { QuoteType } from 'components/quote/Quote';
import { useEffect, useState } from 'react';
import { getEndpoint } from 'utils/helpers';
import { widont } from 'utils/typography';
import { useThemeContext } from 'context/ThemeContext';
import { getGlobalData, GlobalDataType, parseProjectsToParallaxList } from 'utils/parsers';
import CustomMetaData, {
  CustomMetaDataProps,
} from 'components/general/customMetaData/CustomMetaData';

import style from './index.module.scss';
import { useIntroContext } from 'context/IntroContext';

type Props = {
  // title: string;
  heroVideoDesktop: HeroVideoDesktopType;
  heroVideoMobile: HeroVideoMobileType;
  metaData: CustomMetaDataProps;
  intro: PageIntroTextType;
  projects: ProjectType[];
  parallaxList: ParallaxListItem[];
  footer: {
    quote: QuoteType;
    images: ImageType[];
    connectTaglineHtml: string;
  };
} & { globalData: GlobalDataType };

const Index = ({
  intro,
  projects,
  footer,
  parallaxList,
  globalData,
  metaData,
  heroVideoDesktop,
  heroVideoMobile,
}: Props): JSX.Element => {
  const { setTheme } = useThemeContext();
  const { setHtml } = useIntroContext();

  const [heroVisibility, setHeroVisibility] = useState<boolean>(true);
  useEffect(() => {
    setHtml(globalData.intro.html);
  }, [setHtml, globalData.intro.html]);

  useEffect(() => {
    setTheme('dark');
  }, []);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > window.innerHeight * 1.25) {
        setHeroVisibility(false);
      } else {
        setHeroVisibility(true);
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);
  const heroState = !heroVisibility ? style.heroIsHidden : '';
  return (
    <div>
      <CustomMetaData {...metaData} />

      <section className={heroState}>
        <HomepageHero intro={intro} desktopVideo={heroVideoDesktop} mobileVideo={heroVideoMobile} />
      </section>

      <section className={style.carouselContainer}>
        <Drag>
          {projects.map(({ hero, thumb, slug, blurb: tagline, full_name: title }) => {
            return (
              <CarouselCard
                key={slug}
                tagline={tagline}
                title={title}
                thumb={thumb}
                slug={'project/' + slug}
              />
            );
          })}
        </Drag>
      </section>

      <section className={style.footerContainer}>
        <HomepageFooter
          quote={footer.quote}
          images={footer.images}
          connectTaglineHtml={footer.connectTaglineHtml}
          emailHtml={globalData.contact.emailHtml}
          addressHtml={globalData.contact.addressHtml}
          facebook={globalData.social.facebook}
          pintrest={globalData.social.pintrest}
          instagram={globalData.social.instagram}
          linkedin={globalData.social.linkedin}
        />
      </section>

      <section>
        <ParallaxList list={parallaxList} amountOfRows={3} />
      </section>
    </div>
  );
};

export async function getStaticProps(context) {
  const projects = await getEndpoint('/projects/', context);

  const featuredProjects = projects.filter((project) => project.is_featured);
  const parallaxList: ParallaxListItem[] = parseProjectsToParallaxList(featuredProjects);

  const homepage = await getEndpoint('/homepage/', context);

  const globalData = await getGlobalData();

  const heroIndex = Math.floor(Math.random() * homepage.hero.length);

  const props: Props = {
    metaData: {
      titleDefault: homepage.title || '',
      title: homepage.meta_title,
      description: homepage.meta_description,
      image: homepage.meta_image,
    },
    intro: {
      html: widont(homepage.intro),
    },
    projects,
    parallaxList,
    footer: {
      quote: {
        title: homepage.closing_heading,
        quote: homepage.closing_quote,
        name: homepage.closing_attribution,
      },
      images: homepage.thumbs,
      connectTaglineHtml: homepage.contact_blurb,
    },
    heroVideoDesktop: {
      full_width: homepage.hero[heroIndex].full_width || false,
      poster: homepage.hero[heroIndex].image,
      src: homepage.hero[heroIndex].video,
      width: homepage.hero[heroIndex].image_width,
      height: homepage.hero[heroIndex].image_height,
    },
    heroVideoMobile: {
      full_width: homepage.hero[heroIndex].full_width || false,
      poster: homepage.hero[heroIndex].phone_image,
      src: homepage.hero[heroIndex].phone_video,
      width: homepage.hero[heroIndex].phone_image_width,
      height: homepage.hero[heroIndex].phone_image_height,
    },
    globalData,
  };
  // Pass data to the page via props
  return {
    // TODO: how can we add Typescript here?
    props,
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 10 seconds
    revalidate: NEXTJS_REVALIDATE_PAGE_IN_SECONDS, // In seconds
  };
}

export const NEXTJS_REVALIDATE_PAGE_IN_SECONDS = 10;
export default Index;
