import { VideoBlock } from 'components/general/videoBlock/VideoBlock';
import { VideoJSPlayer } from 'components/general/videoBlock/VideoJSPlayer';
import { Logo } from 'components/logo/Logo';
import { PageIntroText, PageIntroTextType } from 'components/pageIntroText/PageIntroText';
import { useWindowSize } from 'hooks/useWindowSize';
import { isBrowser, isMobileWidth } from 'utils/helpers';
import { VideoJsPlayerOptions } from 'video.js';
import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import style from './HomepageHero.module.scss';
import { CSSProperties, useEffect, useRef, useState } from 'react';

if (typeof window !== 'undefined') {
  // Due to SSR we can only register when we are in the browser
  //  https://greensock.com/forums/topic/28936-gsap-and-nextjs-up-to-date-best-practices/
  gsap.registerPlugin(ScrollTrigger);
}

export type HeroVideoDesktopType = {
  poster: string;
  src: string;
  full_width: boolean;
  width: number;
  height: number;
};
export type HeroVideoMobileType = {
  poster: string;
  src: string;
  full_width: boolean;
  width?: number;
  height?: number;
};
export type HomepageHeroType = {
  intro: PageIntroTextType;
  desktopVideo: HeroVideoDesktopType;
  mobileVideo: HeroVideoMobileType;
};
export const HomepageHero = ({
  intro,
  desktopVideo,
  mobileVideo,
}: HomepageHeroType): JSX.Element => {
  const darkenRef = useRef<HTMLDivElement>(null);
  const [isDark, setIsDark] = useState<boolean>(false);

  useEffect(() => {
    const onScroll = () => {
      const threshold = window.innerHeight / 2;
      setIsDark(window.scrollY > threshold);
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [setIsDark]);

  const size = useWindowSize();
  const isPhone = isMobileWidth(size.width);
  const videoJsOptions: VideoJsPlayerOptions = {
    controls: false,
    responsive: true,
    controlBar: false,
    bigPlayButton: false,
    fill: true,
    autoplay: true,
    preload: 'auto',
    loop: true,
    muted: true,
    poster: isPhone && mobileVideo.poster ? mobileVideo.poster : desktopVideo.poster,

    sources: [
      {
        src: isPhone && mobileVideo.src ? mobileVideo.src : desktopVideo.src,
      },
    ],
  };

  const darkClass = isDark ? style.darkenerIsDark : null;
  const fullWidthVideoClass = desktopVideo.full_width ? style.videoFullWidth : null;

  const styles: CSSProperties = {
    ...(isPhone && {
      aspectRatio: `${
        (mobileVideo.width || desktopVideo.width) / (mobileVideo.height || desktopVideo.height)
      }`,
    }),
    ...(!isPhone &&
      !desktopVideo.full_width && { aspectRatio: `${desktopVideo.width / desktopVideo.height}` }),
  };

  return (
    <div className={`${style.main} homepage-hero`}>
      <div className={`${style.heroContainer} `}>
        <div className={`${style.darkener} ${darkClass}`} ref={darkenRef} />
        <div className={`${style.videoPositioner} ${fullWidthVideoClass}`} style={styles}>
          <VideoJSPlayer playsInline={true} options={videoJsOptions} onVideoPlay={() => {}} />
        </div>
      </div>
      <div className={style.container}>
        <div className={style.introContainer}>
          <PageIntroText {...intro} />
        </div>
        <div className={style.logoContainer}>
          <Logo forceLarge={true} />
        </div>
      </div>
    </div>
  );
};
