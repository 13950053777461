import { ImageBlock } from 'components/general/imageBlock/ImageBlock';
import { Link } from 'components/link/Link';
import { MediaType } from 'pages/project/[id]';
import { useInView } from 'react-intersection-observer';
import style from './CarouselCard.module.scss';
import { VideoJSPlayer } from 'components/general/videoBlock/VideoJSPlayer';
import { VideoJsPlayerOptions } from 'video.js';
import { isMobileWidth } from 'utils/helpers';
import { useWindowSize } from 'hooks/useWindowSize';

export type CarouselCardType = {
  title: string;
  tagline: string;
  thumb: MediaType;
  slug: string;
};
export const CarouselCard = ({ title, tagline, slug, thumb }: CarouselCardType): JSX.Element => {
  const size = useWindowSize();
  const isPhone = isMobileWidth(size.width);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.5,
  });

  const videoJsOptions: VideoJsPlayerOptions = {
    controls: false,
    responsive: true,
    controlBar: false,
    bigPlayButton: false,
    fill: true,
    autoplay: true,
    preload: 'auto',
    loop: true,
    muted: true,
    poster: isPhone && thumb.phone_image ? thumb.phone_image : thumb.image,

    sources: [
      {
        src: isPhone && thumb.phone_video ? thumb.phone_video : thumb.video!,
      },
    ],
  };

  const titleState = inView ? style.titleInViewport : null;
  return (
    <Link href={slug}>
      <div className={style.container}>
        <div className={style.copyContainer}>
          <h3 ref={ref} className={`${style.title} ${titleState}`}>
            {title}
          </h3>
          <span className={`${style.titleSpan} ${titleState}`}>{tagline}</span>
        </div>
        <div className={style.imageContainer}>
          <div className={style.relativeParent}>
            {thumb.video ? (
              <VideoJSPlayer playsInline={true} options={videoJsOptions} onVideoPlay={() => {}} />
            ) : (
              <ImageBlock
                desktop={{
                  src: thumb.image,
                  layout: 'fill',
                  objectFit: 'cover',
                  loading: 'eager',
                }}
                alt={thumb.alt_text}
              />
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};
