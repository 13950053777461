import { useEffect, useRef } from 'react';
import { gsap } from 'gsap/dist/gsap';
import { Draggable } from 'gsap/dist/Draggable';
import { InertiaPlugin } from 'gsap/dist/InertiaPlugin';

import style from './Drag.module.scss';
import { isIOS } from 'utils/helpers';

if (typeof window !== 'undefined') {
  // Due to SSR we can only register when we are in the browser
  gsap.registerPlugin(Draggable, InertiaPlugin);
}

export type DragType = {
  children: React.ReactNode;
  snapPoint?: () => number;
  onUpdate?: (pct: number) => void;
};
export const Drag = ({ children, snapPoint, onUpdate = () => {} }: DragType): JSX.Element => {
  const slider = useRef<HTMLDivElement>(null);
  const bounds = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onDraggableUpdate = () => {
      var pct = draggable.x / draggable.minX;
      onUpdate(pct);
    };
    const draggable = Draggable.create(slider.current, {
      type: 'x',
      bounds: bounds.current,
      inertia: true,
      edgeResistance: 0.95,
      dragResistance: 0,
      throwProps: true,
      callbackScope: this,
      zIndexBoost: false,
      allowContextMenu: false,
      snap: {
        x: (value) => {
          if (!snapPoint) return value;

          const min = draggable.minX;
          const snapTo = snapPoint();
          const snap = Math.round(value / snapTo) * snapTo;
          //snap to the closest increment OR last item
          return value <= min ? value : snap;
        },
      },
      onDrag: onDraggableUpdate,
      onMove: onDraggableUpdate,
      onThrowUpdate: onDraggableUpdate,
    })[0];

    const handleResize = () => {
      gsap.killTweensOf(draggable.target);
      gsap.to(draggable.target, { x: draggable.maxX });
    };

    if (isIOS()) {
      window.addEventListener('orientationchange', handleResize);
    } else {
      window.addEventListener('resize', handleResize);
    }

    onDraggableUpdate();
    return () => {
      draggable.kill();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section ref={bounds} className={style.row}>
      <div className={style.wrap}>
        <div ref={slider} className={style.slider}>
          {/* <!-- we'll put the slides in here, can be anything --> */}
          {children}
        </div>
      </div>
    </section>
  );
};
