import { ConnectBlockEmail } from 'components/connectBlocks/ConnectBlockEmail';
import { ConnectBlockSubscribe } from 'components/connectBlocks/ConnectBlockSubscribe';
import { ConnectBlockTagline } from 'components/connectBlocks/ConnectBlockTagline';
import style from './ContactDetails.module.scss';

export type ContactDetailsType = {
  introHtml: string;
  facebookLink: string;
  emailHtml: string;
  instagramLink: string;
  linkedinLink: string;
  addressHtml: string;
  pinterestLink: string;
  hideDetailsOnMobile?: boolean;
};
export const ContactDetails = ({
  introHtml,
  emailHtml,
  addressHtml,
  instagramLink,
  linkedinLink,
  facebookLink,
  pinterestLink,
  hideDetailsOnMobile = false,
}: ContactDetailsType): JSX.Element => {
  const hideOnMobile = hideDetailsOnMobile ? style.hideOnMobile : null;
  return (
    <div className={style.container}>
      <div className={style.tagLine}>
        <ConnectBlockTagline intro={introHtml} />
      </div>
      <div className={`${style.email} ${hideOnMobile}`}>
        <ConnectBlockEmail html={emailHtml} />
      </div>
      <div className={`${style.address} ${hideOnMobile}`}>
        <ConnectBlockEmail html={addressHtml} />
      </div>
      <div className={`${style.subscribe} ${hideOnMobile}`}>
        <ConnectBlockSubscribe
          facebookLink={facebookLink}
          instagramLink={instagramLink}
          linkedinLink={linkedinLink}
          pinterestLink={pinterestLink}
        />
      </div>
    </div>
  );
};
