import { Link } from 'components/link/Link';
import { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap/dist/gsap';

import { isMobileWidth, splitToChunks } from '../../utils/helpers';
import { mapRange } from '../../utils/math';
import { ImageType } from 'pages/project/[id]';
import style from './ParallaxList.module.scss';
import { Follower } from './Follower';

export type ParallaxListItem = {
  id: string;
  type: string;
  title: string;
  color: string;
  img: ImageType;
};
export type ParallaxListType = {
  list: ParallaxListItem[];
  amountOfRows?: number;
};
export const ParallaxList = ({ list, amountOfRows = 3 }: ParallaxListType): JSX.Element => {
  const main = useRef<HTMLDivElement>(null);
  const followContainer = useRef<HTMLDivElement>(null);
  const [currentItem, setCurrentItem] = useState<ParallaxListItem | undefined>(undefined);
  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const onScroll = () => {
    if (isMobileWidth(window.innerWidth)) {
      const items = main.current?.querySelectorAll('[data-slug]') || [];
      let currentClosestDistance = 999999999999;
      let currentDeltaY = 999999999999;
      const threshold = window.innerHeight / 4;
      let currentIndexClosestToCenter = -1;
      const children = [...items];
      children.forEach((item, index) => {
        const { top } = item.getBoundingClientRect();
        const ypos = top; //+ window.scrollY;
        const dx = 0;
        const dy = ypos - threshold;
        const distanceToPoint = Math.sqrt(dx * dx + dy * dy);
        if (distanceToPoint < currentClosestDistance) {
          currentClosestDistance = distanceToPoint;
          currentIndexClosestToCenter = index;
          currentDeltaY = top;
          const slug = item;
        }
      });
      const active = list[currentIndexClosestToCenter];
      const lastItemIsSelected = currentIndexClosestToCenter === children.length - 1;

      const scrolledPastLast = lastItemIsSelected && currentDeltaY < threshold - 100;

      if (scrolledPastLast) {
        //fadeOut
        // gsap.to(followContainer.current, { alpha: 0 });
      } else {
        //fadeIn
        gsap.to(followContainer.current, { alpha: 0.5 });
      }
      if (currentClosestDistance > window.innerHeight / 1) {
        setCurrentItem(undefined);
      } else {
        setCurrentItem(active);
      }
    } else {
      setCurrentItem(undefined);
    }
  };

  const onResize = () => {
    onMouseMove({ pageX: 0, pageY: 0 });
  };
  const onMouseMove = (e) => {
    if (main.current) {
      const { pageX, pageY } = e;
      const yPos = pageY - window.scrollY;
      const xPos = pageX - window.scrollX;

      gsap.utils.toArray(main.current.children).forEach((element: any, index: number) => {
        const dx = 0;
        const dy = element.getBoundingClientRect().y - yPos;
        const distance = Math.sqrt(dx * dx + dy * dy);
        const maxDistance = 300;
        const distPct = Math.abs(distance / maxDistance);
        const padding = 150;
        const pct = mapRange(xPos, 0 + padding, window.innerWidth - padding, 0, 1, true);
        const overflow = element.scrollWidth - (window.innerWidth - 20);
        const targetX = pct * overflow;
        const direction = -1;
        const duration = 1;
        // TODO: needs some tweaking
        gsap.to(element, { duration, x: direction * targetX, ease: 'power4.out' });
        if (element.classList.contains('is-active')) {
        } else {
          // gsap.to(element, { duration, x: direction * targetX * distPct, ease: 'power4.out' });
        }
      });
    }
  };

  const onMouseEnter = (e) => {
    e.currentTarget.classList.add('is-active');
  };
  const onMouseLeave = (e) => {
    e.currentTarget.classList.remove('is-active');
  };

  // TODO: typescript implementation of this util
  const listItemsPerRow = splitToChunks(list, 3);

  return (
    <div className={style.container}>
      <div ref={main} className={style.rows}>
        {/* TODO: we should need typing once the util class returns correct types */}
        {listItemsPerRow.map((rowList: ParallaxListItem[], index) => {
          return (
            <div
              key={index}
              className={style.row}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              {rowList.map((item, listIndex) => {
                const isSelected = item.id === currentItem?.id;
                const rowItemState = isSelected ? style.rowItemSelected : null;
                return (
                  <Link href={`/project/${item.id}`} key={listIndex}>
                    <a
                      className={`${style.rowItem} ${rowItemState}`}
                      onMouseEnter={() => {
                        setCurrentItem(item);
                      }}
                      onMouseLeave={() => {
                        setCurrentItem(undefined);
                      }}
                      data-slug={item.id}
                    >
                      {listIndex === 0 ? null : <span className={style.rowItemSeperator}> - </span>}
                      <span className={style.rowItemCategory}>{item.type}</span>
                      <span className={style.rowItemSeperator}>/</span>
                      <span className={style.rowItemTitle}>{item.title}</span>
                      <span className={`${style.rowItemCategoryMobile} ${style.rowItemCategory}`}>
                        {/* {item.type} */}
                      </span>
                    </a>
                  </Link>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className={style.category}>
        <Link href="/work">
          <a className={style.link}>Drinks</a>
        </Link>
      </div>
      <div ref={followContainer} className={style.followerContainer}>
        <Follower item={currentItem} />
      </div>
    </div>
  );
};
