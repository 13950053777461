import { Link } from 'components/link/Link';
import { useEffect, useRef } from 'react';
import style from './HomepageFooter.module.scss';
import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { ImageType } from 'pages/project/[id]';
import { ConnectBlockTagline } from 'components/connectBlocks/ConnectBlockTagline';
import { ConnectBlockEmail } from 'components/connectBlocks/ConnectBlockEmail';
import { ConnectBlockAddress } from 'components/connectBlocks/ConnectBlockAddress';
import { ConnectBlockSubscribe } from 'components/connectBlocks/ConnectBlockSubscribe';
import { Quote, QuoteType } from 'components/quote/Quote';
import { ImageBlock } from 'components/general/imageBlock/ImageBlock';
import { ContactDetails } from 'components/contactDetails/ContactDetails';
import { isMobileWidth } from 'utils/helpers';
import { widont } from 'utils/typography';

if (typeof window !== 'undefined') {
  // Due to SSR we can only register when we are in the browser
  //  https://greensock.com/forums/topic/28936-gsap-and-nextjs-up-to-date-best-practices/
  gsap.registerPlugin(ScrollTrigger);
}

type Props = {
  quote: QuoteType;
  images: ImageType[];
  emailHtml: string;
  addressHtml: string;
  facebook: string;
  pintrest: string;
  instagram: string;
  linkedin: string;
  connectTaglineHtml: string;
};
export const HomepageFooter = ({
  quote,
  images,
  connectTaglineHtml,
  emailHtml,
  addressHtml,
  facebook,
  pintrest,
  instagram,
  linkedin,
}: Props): JSX.Element => {
  const parallaxContainer = useRef<HTMLDivElement>(null);
  const main = useRef<HTMLDivElement>(null);

  // TODO: maybe some stikcy effect to the image when mouse if closer?
  useEffect(() => {
    if (!parallaxContainer.current || !main.current)
      throw new Error('Container or main has no ref');

    const animations: any[] = [];
    const duration = 0.2;
    gsap.utils.toArray(parallaxContainer.current.children).forEach((element: any, index) => {
      const offset = isMobileWidth(window.innerWidth) ? 400 : 400;
      const yOffset = offset * (index + 1); //+ Math.random() * window.innerHeight;
      const animation = gsap.to(element, {
        opacity: 1,
        ease: 'none',
        scrollTrigger: {
          trigger: parallaxContainer.current,
          start: 'top bottom',
          markers: false,
          onUpdate: (self) => {
            if (main.current) {
              gsap.to(element, {
                y: yOffset * (1 - self.progress),
                duration,
              });
            }
          },
        },
      });
      animation.progress(0);
      animations.push(animation);

      return () => {
        animations.forEach((animation) => animation.scrollTrigger.kill());
      };
    });
  }, []);

  return (
    <div ref={main} className={style.container}>
      <div ref={parallaxContainer} className={style.parallaxContainer}>
        {images.map((img, index) => {
          return (
            <div key={index} className={style.parallaxItem} data-parallax>
              <div className={style.parallaxRelative}>
                <ImageBlock
                  desktop={{
                    src: img.image,
                    width: img.image_width,
                    height: img.image_height,
                    className: style.img,
                    layout: 'responsive',
                    // layout: 'fill',
                    // objectFit: 'cover',
                  }}
                  alt={img.alt_text}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className={style.contentContainer}>
        <Link href="/about">
          <a className={style.buttonApproach}>About </a>
        </Link>
        <Link href="/culture">
          <a className={style.buttonCulture}>Culture </a>
        </Link>
        <p className={`${style.quoteTitle} `}>{quote.title} </p>
        <div className={style.quoteContainer}>
          <blockquote
            className={`${style.quote} `}
            dangerouslySetInnerHTML={{ __html: widont(quote.quote) }}
          />
          <blockquote
            className={`${style.quoteName} `}
            dangerouslySetInnerHTML={{ __html: widont(quote.name) }}
          />
        </div>

        <div className={style.footer}>
          <ContactDetails
            hideDetailsOnMobile={true}
            introHtml={connectTaglineHtml}
            emailHtml={emailHtml}
            addressHtml={addressHtml}
            facebookLink={facebook}
            pinterestLink={pintrest}
            instagramLink={instagram}
            linkedinLink={linkedin}
          />
        </div>
      </div>
    </div>
  );
};
