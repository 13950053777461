export const mapRange = (
  value,
  inputMin,
  inputMax,
  outputMin,
  outputMax,
  clamp
) => {
  // Reference:
  // https://github.com/mattdesl/canvas-sketch-util/blob/852fee9564c85d535ef82cc59f9b857b1bf08a3d/math.js#L164
  if (Math.abs(inputMin - inputMax) < Number.EPSILON) {
    return outputMin;
  } else {
    var outVal =
      ((value - inputMin) / (inputMax - inputMin)) * (outputMax - outputMin) +
      outputMin;
    if (clamp) {
      if (outputMax < outputMin) {
        if (outVal < outputMax) outVal = outputMax;
        else if (outVal > outputMin) outVal = outputMin;
      } else {
        if (outVal > outputMax) outVal = outputMax;
        else if (outVal < outputMin) outVal = outputMin;
      }
    }
    return outVal;
  }
};
