import { useEffect, useRef } from 'react';
import { gsap } from 'gsap/dist/gsap';

import style from './Follower.module.scss';
import { ParallaxListItem } from './ParallaxList';
import { ImageBlock } from 'components/general/imageBlock/ImageBlock';
import { isMobileWidth } from 'utils/helpers';
import { useWindowSize } from 'hooks/useWindowSize';

export type FollowerType = {
  item?: ParallaxListItem;
};
export const Follower = ({ item }: FollowerType): JSX.Element => {
  const imageRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const windowSize = useWindowSize();
  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  const onMouseMove = (e) => {
    if (imageRef.current && containerRef.current) {
      const { pageX, pageY } = e;
      const mouseX = pageX - window.scrollX;
      const mouseY = pageY - window.scrollY;

      const imageRect = imageRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();
      const paddingX = 0;
      const paddingY = 40;
      const xPos = Math.max(
        paddingX,
        Math.min(window.innerWidth - imageRect.width - paddingX, mouseX - imageRect.width / 2),
      );

      const minY = containerRect.top - paddingY;
      const yPos = Math.max(minY, mouseY - imageRect.height / 2);
      const phone = isMobileWidth(window.innerWidth);
      gsap.to(imageRef.current, {
        duration: 1,
        x: phone ? 0 : xPos,
        y: phone ? 0 : yPos,
        ease: 'power4.out',
        // rotation: (xPos / window.innerWidth) * 90,
      });
    }
  };

  const time = 0.35;
  const bgColour = isMobileWidth(windowSize.width) ? '#000000' : item ? item.color : '#000000';

  return (
    <>
      <div ref={containerRef} className={style.container} style={{ background: bgColour }}>
        <div ref={imageRef} className={style.imageContainer}>
          {item && (
            <ImageBlock
              key={item.img.image}
              desktop={{
                src: item.img.image,
                // width: item.img.image_width,
                // height: item.img.image_height,
                layout: 'fill',
                objectFit: 'cover',
              }}
              alt={item.img.alt_text}
            />
          )}
        </div>
      </div>
    </>
  );
};
