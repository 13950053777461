import React from 'react';
import Head from 'next/head';

export type CustomMetaDataProps = {
  title: string;
  titleDefault: string;
  description: string;
  image: string;
};

export const SITE_TITLE = 'Co-Partnership';

const CustomMetaData = (props: CustomMetaDataProps) => {
  const { title, description, image, titleDefault } = props;

  const subPageTitle = `${titleDefault}|${SITE_TITLE}`;
  const newTitle = `${title ? title : titleDefault ? subPageTitle : SITE_TITLE}`;


  return (
    <Head>
      <title>{newTitle}</title>
      <meta property="og:title" content={newTitle} key="title" />
      {description && <meta name="description" property="og:description" content={description} key="description" />}
      {image && <meta property="og:image" content={image} key="image" />}
    </Head>
  );
};

export default CustomMetaData;
