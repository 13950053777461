import Image, { ImageProps } from 'next/image';
import { CSSProperties, useState } from 'react';
import style from './ImageBlock.module.scss';

export type BlockImageProps = ImageProps & {};

export type ImageBlockType = {
  desktop: BlockImageProps;
  mobile?: BlockImageProps;
  alt: string;
  loadColor?: string;
  loadFadeTime?: string;
  onLoadComplete?: () => void;

  // useRelativeParent: boolean;
};
//  docs: https://nextjs.org/docs/basic-features/image-optimization and https://nextjs.org/docs/api-reference/next/image

export const ImageBlock = ({
  desktop,
  mobile,
  alt = '',
  loadColor,
  loadFadeTime = '0.4s',
  onLoadComplete,
}: // useRelativeParent = true,
ImageBlockType): JSX.Element => {
  const [isDesktopLoaded, setIsDesktopLoaded] = useState<boolean>(false);
  const [isMobileLoaded, setIsMobileLoaded] = useState<boolean>(false);

  const onDesktopLoadingComplete = () => {
    setIsDesktopLoaded(true);
    onLoadComplete && onLoadComplete();
  };
  const onMobileLoadingComplete = () => {
    setIsMobileLoaded(true);
    onLoadComplete && onLoadComplete();
  };
  const mobileLoadState = isMobileLoaded ? style.imageLoaded : null;
  const desktopLoadState = isDesktopLoaded ? style.imageLoaded : null;

  const canSwap = mobile && desktop;
  const desktopClass = canSwap ? style.desktopImageCanSwap : style.desktopImage;
  // console.log({ useRelativeParent, desktop: desktop.src });
  const inlineStyleDesktop: CSSProperties = {
    ...(loadColor && !isDesktopLoaded && { background: loadColor }),
    ...{ '--image-transition-time': loadFadeTime },
  };
  const inlineStyleMobile: CSSProperties = {
    ...(loadColor && !isMobileLoaded && { background: loadColor }),
    ...{ '--image-transition-time': loadFadeTime },
  };

  return (
    <>
      {mobile && (
        <div
          style={inlineStyleMobile}
          className={`${style.mobileImage} ${mobile.className} ${mobileLoadState} block-image-mobile`}
        >
          <Image {...mobile} alt={alt} onLoadingComplete={onMobileLoadingComplete} />
        </div>
      )}
      <div
        style={inlineStyleDesktop}
        className={`${desktopClass} ${desktopLoadState} ${desktop.className} block-image-desktop`}
      >
        <Image {...desktop} alt={alt} onLoadingComplete={onDesktopLoadingComplete} />
      </div>
    </>
  );
};
